<template>
  <el-form ref="rechargeRecordFormRef" :inline="true" :model="rechargeRecordForm" style="padding-top: 15px">
    <el-row justify="space-between">
      <el-col :span="22">
        <el-row>
          <el-form-item label="账户ID" prop="accountId">
            <el-input placeholder="账户ID" size="small" clearable v-model.trim="rechargeRecordForm.accountId"
              style="width: 220px"></el-input>
          </el-form-item>
          <el-form-item label="选择日期" prop="timeRange">
            <el-date-picker v-model="rechargeRecordForm.timeRange" type="daterange" size="small" clearable
              start-placeholder="开始日期" end-placeholder="结束日期" value-format="X" style="width: 280px;"></el-date-picker>
          </el-form-item>
          <el-form-item label="媒体平台" prop="type">
            <el-select v-model="rechargeRecordForm.type" clearable placeholder="请选择媒体平台" size="small"
              style="width: 220px">
              <el-option label="Facebook" value="1"></el-option>
              <el-option label="TikTok" value="2"></el-option>
              <el-option label="Google" value="3"></el-option>
              <el-option label="微软广告" value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="rechargeRecordForm.status" clearable placeholder="请选择状态" size="small"
              style="width: 220px">
              <el-option label="成功" value="1"></el-option>
              <el-option label="失败" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 14px">
            <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
            <el-button @click="resetFormBtn" size="small">重置</el-button>
          </el-form-item>
        </el-row>
      </el-col>
      <el-col :span="2" style="text-align: right">
        <el-button type="text" class="LinkText" @click="downLoadData">下载</el-button>
      </el-col>
    </el-row>
  </el-form>

  <el-table ref="rechargeRecordListRef" :data="rechargeRecordList" style="width: 100%" :header-cell-style="{
    background: 'var(--el-table-row-hover-background-color)',
  }">
    <el-table-column property="account" label="账户ID" min-width="200" />
    <el-table-column property="account_name" label="账户名称" min-width="200" />
    <el-table-column property="media_type" label="媒体平台" min-width="150" />
    <el-table-column property="money" label="充值金额" min-width="140">
      <template #default="{ row }">
        <span>${{ $filters.thousands(row.money) }}</span>
      </template>
    </el-table-column>
    <el-table-column property="service_charge" label="服务费" min-width="120" />
    <el-table-column property="google_money" label="Google开户费" min-width="130" />
    <el-table-column property="actual_amount" label="实际到账" min-width="120">
      <template #default="{ row }">
        <span>${{ $filters.thousands(row.actual_amount) }}</span>
      </template>
    </el-table-column>
    <el-table-column property="status" label="状态" min-width="180">
      <template #default="{ row }">
        <el-tag :type="formatStatus(row)">{{ row.status }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column property="msg" label="备注" min-width="130" />
    <el-table-column property="is_yc" label="是否YC操作" min-width="150" />
    <el-table-column property="operator" label="操作人" min-width="150" />
    <el-table-column property="create_time" label="创建时间" min-width="160" />
    <template #empty>
      <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
    </template>
  </el-table>

  <!-- 分页器 -->
  <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
    :page-sizes="[5, 10]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total"
    background>
  </el-pagination>

  <!-- 下载付款记录弹窗 -->
  <el-dialog v-model="downLoadDialogVisible" title="下载充值记录" width="790px">
    <!-- 内容主体区域 -->
    <el-form ref="downLoadFormRef" label-width="100px">
      <el-form-item label="账户ID">
        <el-input placeholder="账户ID" size="small" clearable v-model.trim="rechargeAccountId"
          style="width: 220px"></el-input>
      </el-form-item>
      <el-form-item label="选择时间" class="timeRange">
        <el-date-picker v-model="rechargeTimeRange" type="daterange" size="small" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="X"></el-date-picker>
      </el-form-item>
      <el-form-item label="媒体平台">
        <el-select v-model="rechargeMethod" placeholder="请选择媒体平台" size="small" clearable>
          <el-option label="Facebook" value="1"></el-option>
          <el-option label="TikTok" value="2"></el-option>
          <el-option label="Google" value="3"></el-option>
          <el-option label="微软广告" value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="rechargeStatus" clearable placeholder="请选择状态" size="small">
          <el-option label="成功" value="1"></el-option>
          <el-option label="失败" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择字段" class="downLoadForm">
        <el-transfer v-model="rechargeValue" style="text-align: left; display: inline-block"
          :left-default-checked="['media_type', 'status', 'msg', 'create_time', 'google_money', 'is_yc', 'operator']"
          :right-default-checked="[]" :titles="['可选字段', '已选字段']" :button-texts="['移除', '添加']" :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}',
          }" :data="rechargeData">
        </el-transfer>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="downLoadFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="downLoadFormBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElLoading } from 'element-plus'

export default {
  name: 'PayList',
  data() {
    return {
      // 表单查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5,
      },
      total: 5,

      rechargeRecordForm: {
        accountId: '',
        timeRange: '',
        type: '',
        status: '',
      },
      rechargeRecordList: [],
      downLoadDialogVisible: false,
      rechargeAccountId: '',
      rechargeTimeRange: '',
      rechargeMethod: '',
      rechargeStatus: '',
      rechargeValue: ['account', 'money', 'service_charge', 'actual_amount'],
      rechargeData: [
        {
          key: 'account',
          label: '账户ID',
          disabled: true,
        },
        {
          key: 'media_type',
          label: '媒体平台',
        },
        {
          key: 'money',
          label: '金额',
          disabled: true,
        },
        {
          key: 'service_charge',
          label: '服务费',
          disabled: true,
        },
        {
          key: 'google_money',
          label: 'Google开户费',
        },
        {
          key: 'actual_amount',
          label: '实际到账',
          disabled: true,
        },
        {
          key: 'status',
          label: '状态',
        },
        {
          key: 'msg',
          label: '备注',
        },
        {
          key: 'create_time',
          label: '创建时间',
        },
        {
          key: 'operator',
          label: '操作人',
        },
        {
          key: 'is_yc',
          label: '是否YC操作',
        },
      ],
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getRechargeRecordList()
  },
  methods: {
    formatStatus(row) {
      if ('充值成功' === row.status) return 'success'
      if ('充值失败' === row.status) return 'danger'

      return ''
    },
    queryFormBtn() {
      this.getRechargeRecordList()
    },
    resetFormBtn() {
      this.$refs.rechargeRecordFormRef.resetFields()
      this.getRechargeRecordList()
    },
    async getRechargeRecordList() {
      const { data: res } = await this.$http.post('cloudfb/recharge_account_list', {
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        account: this.rechargeRecordForm.accountId,
        timeRange: this.rechargeRecordForm.timeRange,
        type: this.rechargeRecordForm.type,
        status: this.rechargeRecordForm.status,
      })

      if (res.code == 300) {
        this.$router.push('/login')
      }
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
      this.rechargeRecordList = res.list
      this.total = res.data.total
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getRechargeRecordList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getRechargeRecordList()
    },
    downLoadData() {
      if (this.rechargeRecordList == '') {
        return this.$message({
          showClose: true,
          message: '暂无数据',
          type: 'warning',
        })
      }
      this.downLoadDialogVisible = true
    },
    downLoadFormBtn() {
      var rechargeValue = this.rechargeValue.join(',')
      const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '下载中，请稍后...',
      })

      this.$http({
        method: 'post',
        url: 'cloudfb/recharge_account_export',
        data: {
          token: this.userInfo.token,
          account: this.rechargeAccountId,
          timeRange: this.rechargeTimeRange,
          field: rechargeValue,
          type: this.rechargeMethod,
          status: this.rechargeStatus,
        },
        responseType: 'blob', // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          // 处理返回的文件流
          const content = res.data
          const blob = new Blob([content])
          const fileName = '充值记录.xls'
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
        .finally(() => {
          loading.close()
        })
      this.downLoadDialogVisible = false
    },
    downLoadFormClose() {
      this.downLoadDialogVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
.timeRange {
  :deep(.el-date-editor.el-input__inner) {
    width: 220px !important;
  }
}

.downLoadForm {
  .el-checkbox {
    width: 100px;
  }

  :deep(.el-transfer__button) {
    display: block;
    min-height: 32px;
    padding: 9px 15px;
    font-size: 12px;
  }

  :deep(.el-transfer__button:nth-child(2)) {
    margin-left: 0 !important;
  }
}
</style>
