import axios from '@/request/http'
import base from '@/request/base'

// 获取消费流水
export const getConsumptionFlow = (params) => {
  return axios.get(`${base.admin_url}/cloud/recharge/flow`, { params: params })
}

// 下载消费流水
export const downloadConsumptionFlow = (timeRange) => {
  return axios.get(`${base.admin_url}/cloud/recharge/flow_export`, {
    params: timeRange,
    responseType: 'blob',
  })
}

// 获取减款记录
export const getDeduction = (params) => {
  return axios.get(`${base.admin_url}/cloud/cloudfb/reduce_list`, { params: params })
}

// 撤回申请中的余额清零
export const resetRevoke = (params) => {
  return axios.post(`${base.admin_url}/cloud/cloudfb/reset_revoke?id=${params}`)
}
